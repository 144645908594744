<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm" />
    <TopicForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      refForm="updateForm"
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
      :loading="isLoading"
      v-if="item"
      :parent="parent"
    />
    <v-data-table
      :headers="headers"
      :items="subTopics"
      :items-per-page="15"
      :loading="isLoading"
      :loading-text="$t('Loading...')"
      :server-items-length="item.children.length"
      class="elevation-1"
      item-key="@id"
      v-if="item.children && item.children.length"
    >
      <template #item.enabled="{ item }">{{ item.enabled === true ? enabledStatus.yes : enabledStatus.no }}</template>
      <ActionCell
        :handle-delete="() => deleteHandler(props.item)"
        :handle-edit="() => editHandler(props.item)"
        slot="item.action"
        slot-scope="props"
      ></ActionCell>
    </v-data-table>
    <Loading :visible="isLoading || deleteLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TopicForm from '../../components/topic/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import UpdateMixin from '../../mixins/UpdateMixin';
import ActionCell from '../../components/ActionCell';
import TopicMixin from '@/mixins/TopicMixin';

const servicePrefix = 'Topic';

export default {
  name: 'TopicUpdate',
  servicePrefix,
  mixins: [UpdateMixin, TopicMixin],
  data() {
    return {
      updateMessage: this.$t('TopicUpdateSuccess'),
      deleteMessage: this.$t('TopicDeleteSuccess'),
      updateRoute: null,
      headers: [
        { text: this.$i18n.t('name'), value: 'name' },
        { text: this.$i18n.t('enabled'), value: 'enabled' },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      enabledStatus: {
        yes: this.$i18n.t('yes'),
        no: this.$i18n.t('no')
      }
    };
  },
  components: {
    Loading,
    Toolbar,
    TopicForm,
    ActionCell
  },
  computed: {
    ...mapFields('topic', {
      deleteLoading: 'isLoading',
      isLoading: 'isLoading',
      error: 'error',
      deleted: 'deleted',
      updated: 'updated',
      violations: 'violations',
      totalItems: 'totalItems',
      resetList: 'resetList'
    }),
    ...mapGetters('topic', ['find']),
    subTopics() {
      return this.item.children.map((subTopic) => this.resolveTopic(subTopic)).filter((e) => e);
    },
    parent() {
      return this.item['parent'] ? this.resolveTopic(this.item['parent']) : {};
    }
  },
  methods: {
    ...mapActions('topic', {
      deleteItem: 'del',
      retrieve: 'load',
      update: 'update',
      getPage: 'fetchAll'
    }),
    editHandler(item) {
      this.retrieve(item['@id']).then(() => {
        this.$router.push({
          name: `${this.$options.servicePrefix}Update`,
          params: { id: item['@id'] }
        });
      });
    },
    deleteHandler(item) {
      this.deleteItem(item);
    },
    deleteWatcher(deleted) {
      if (!deleted) {
        return;
      }
      this.showMessage(this.deleteMessage);
      this.retrieve(deleted.parent);
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.id !== from.params.id) {
      this.loadObjectToUpdate().then(() => this.doResetForm());
    }
    next();
  }
};
</script>
